body {
  margin: 0;
  background-color: whitesmoke;
  font-family: 'Cairo', sans-serif;
  /* padding-bottom: 15%; */
}
.row{
  width: 100%;
}
button{
  cursor: pointer;
}
/* button:focus{
  outline: none;
} */
.admin_login_form_container{
  width: 90%;
  max-width: 300px;
  background-color: white;
  margin-top: 10%;
  border: solid lightgray 1px;
  padding: 20px;
  border-radius: 5px;
}
.small_text{
  font-size: 13px;
}
.admin_home_btns{
  margin: 2%;
}
.admin_home_btns .btn{
  width: 90%;
  margin-bottom: 5%;
  display: inline-block;
}

.menu_card{
  width: 90%;
  margin-top: 25px;
}
.menu_cards_container{
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
}
.menu_cards_container p{
  font-size: 15px;
}
.jumbotron{
  margin-top: 5%;
  padding: 5%;
  padding-top: 10%;
  padding-bottom: 10%;
}
.jumbotron .btn{
  width: 70%;
  margin-left: 2%;
  margin-right: 2%;
}
.jumbotron .delete_category_btn{
  width: 45%;
  margin-bottom: 10px;
}

.show_category_btn{
  width: 90%;
}
.category_container_page{
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.category_details{
  width: 100%;
  max-width: 800px;
}
.category_items_list_container{
  width: 90%;
  max-width: 800px;
}
.item_action_button_on_category{
  width: 40px;
  border: none;
  background-color: white;
  cursor: pointer;
}
.offer_on_list{
  display: block;
}
.modal_container{
  position: fixed;
  background-color: #00000099;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.edit_modal_frame{
  background-color: white;
  margin-top: 10%;
  padding: 5%;
  padding-top: 3%;
  width: 90%;
  max-width: 700px;
  border-radius: 10px;
}
.edit_modal_frame .input_width{
  max-width: 350px;
}
.edit_modal_header{
  text-align: right;
}
.close_modal_button{
  background-color: #dc3545;
  color: white;
  border-radius: 50px;
  width: 40px;
  height: 30px;
  border: none;
  cursor: pointer;
}
.edit_submit_btn{
  margin-top: 10px;
  width: 100%;
}
.status_item_btn{
  width: 100%;
}
.delete_item_actions_container button{
  width: 90%;
}
.add_category_btn{
  margin: 3px;
  width: 90%;
  max-width: 300px;
}
.staff_page{
  padding: 2%;
}
.card-body{
  text-align: left;
}
.staff_card_actions button{
  width: 70%;
}
.staff_card_actions .delete_category_btn{
  width: 50px;
}
.staff_card_actions{
  width: 100%;
}
.staff_card_actions button{
  margin-left: 5px;
  margin-right: 5px;
}
.add_category_btn{
  margin-bottom: 20px;
}
.order_category_button{
  display: inline-block;
  width: fit-content;
  height: fit-content;
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
}
.align_left{
  text-align: left;
}

.card-img-top{
  height: 200px;
  width: fit-content;
}
.align_left_card .col-10{
  padding: 0px;
  text-align: left;
}
.opacity_layer{
  background-color: #000000e3;
}

/* ######################################################################################## */
/* ######################################################################################## */
/* ######################################################################################## */
/* ######################################################################################## */
/* user styles */

.menu_background{
  background-image: url('./static/3.jpg');
  background-attachment: scroll;
  background-repeat: repeat ;
  background-size: 100%;
  background-position: top;
  min-height: 100vh;
}
.menu_header{
  width: 100%;
  /* background-color: #000000ad; */
  /* background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.726), #00000000); */
  padding-bottom:  10px;
}
.hedaer_logo{
  height: 90px;
  margin-top: 15px;
}


.ads_picture{
  width: 90vw;
  max-width: 500px;
  margin-top: 10px;
  border-radius: 5px;
  
}
.marging_zero{
  margin: 0px;
}

.lang_ar{
  text-align: right;
  /* margin: 5%; */
  direction: rtl;
}
.ltr{
  direction: ltr;
}
.lang_en{
  text-align: left;
}
.menu_title{
  font-size: 20px;
  font-weight: bold;
  color: #fdfd54;
  margin: 0px;
  margin-top: 10px;
  width: 100%;
}
.menu_title_en{
  text-align: left;
}

.menu_items_container{
  /* padding: 3%; */
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: dashed 2px whitesmoke;
}
.menu_item_name{
  color: white;
  margin-bottom: 0px;
  font-weight: bold;
}
.menu_item_info{
  color: white;
  font-size: 12px;
  margin: 0px;
}
.menu_item_price{
  font-size: 14px;
  color: #fdfd54;
  font-weight: bold;
  margin: 0px;
}
.menu_item_price_title{
  color: white;
  /* font-weight: bold; */
  font-size: 13px;
  margin: 0px;
}

.footer_space{
  height: 100px;
}
.member_page_container{
  padding: 5%;
}
.member_name{
  font-size: 23px;
  font-weight: bold;
  color: #dc3545;
}
.div_as_hr{
  border-bottom: solid lightgray 2px;
  width: 50%;
  padding-top: 10px;
  margin-bottom: 15px;
}
.member_Job{
  font-size: 19px;
  color: #353535;
}
.member_menu_header{
  background-color: whitesmoke;
}
.member_phone{
  color: #353535;
  font-size: 17px;
  margin: 0px;
  margin-bottom: 10px;
}
.download_contact_button{
  background-color: whitesmoke;
  border: none;
}
.logo_to_left{
  text-align: left;
  padding-left: 10%;
}
.logo_to_right{
  text-align: right;
  padding-right: 10%;
}
.lang_button_div{
  width: 100%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}
.lang_button{
  /* position: absolute;
  left: 15px;
  top:  230px; */
  background-color: white;
  width: 35px;
  height: 35px;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 50px;
  border: solid lightgray 2px;
  z-index: 90;
}
.lang_modal{
  position: fixed;
  background-color: #000000aa;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 89;
  padding-top: 30vh;
  text-align: center;
}
.lang_selector_container{
  background-color: whitesmoke;
  border-radius: 10px;
  width: 80%;
  max-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selected_languge_button{
  width: 80%;
  margin: 3%;
  border-radius: 10px;
  padding: 5px;
  border: none;
  background-color: lightgray;
}
.selected_languge_button:active{
  outline: none;
}
.selected_languge_button:focus{
  outline: none;
}
.selected_languge_button_active{
  background-color: #2275d3;
  color: white;
}
.loading_modal{
  background-color: white;
  z-index: 150;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 25vh;
}
.box_container{
  position: relative;
}
.box_container img{
  animation: mirrorflip 3s 0s linear infinite;
}

.box_img1{
  height: 100px;

}

/* @keyframes mirrorflip {
	0% {
		transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		
	}
	50% {
		transform: scale(-1, 1);
		-webkit-transform: scale(-1, 1);
		-moz-transform: scale(-1, 1);
		-o-transform: scale(-1, 1);
		-ms-transform: scale(-1, 1);
	}
	100% {
		transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
	}
} */
@keyframes mirrorflip {
	0% {
		scale: 1;
	}
	50% {
    scale: 1.2;
	}
	100% {
    scale: 1;
	}
}
.icon-minus-circle{
  color: orangered;
}

.ads_picture_container{
  width: 90%;
  max-width: 500px;
  height: 25vh;
  max-height: 250px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}
.list-group{
  font-size: 13px;
}
.footer_footer{
  background-color: #222222;
  color: white;
  font-size: 11px;
  padding-top: 10px;
  padding-bottom: 1px;
}
.menu_card_container{
  direction: rtl;
}
.area_title{
  color: white;
  font-weight: bold;
}
.lang_button_continer_on_member{
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.icon-user-add{
  color: #2c2c2c;
  cursor: pointer;
}
.area_title{
  color: #fdfd54;
}

.category_desc_pic_ar{
  text-align: left;
}
.category_desc_pic_en{
  text-align: right;
}
.category_pic{
  height: 150px;
  width: fit-content;
  margin-bottom: -50px;
}

.admin_org_card{
  background-color: white;
  margin-top: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid lightgray 1px;
  border-radius: 10px;
}
.link_to_btn{
  display: inline-block;
  text-decoration: none;
  color: white;
  width: 80%;
}
.link_to_btn:hover{
  text-decoration: none;
  color: white;
}



.org_login_form_container{
  border-radius: 25px;
  background-color: white;
  width: 40%;
  min-width: 230px;
  padding: 3%;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: left;
  margin-top: 5%;
}
.org_login_form_container label{
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}
.input_filed{
  width: 100%;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border: solid lightgray 1px;
  border-radius: 50px;
}
.org_form_button{
  background-color: #2275d3;
  color:  white;
  border: none;
  width: 50%;
  padding: 8px;
  border-radius: 50px;
  margin-top: 5%;
}

.org_admin_user_action_button{
  background-color: white;
  border: none;
  width: 40%;
  font-weight: bold;
  margin-top: 15px;
}
.org_admin_user_card{
  background-color: white;
  margin-bottom: 20px;
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 20px;
  width: 90%;
  min-width: 180px;
}
.org_admin_user_card p{
  color: gray;
}
.edit{
  color: #2275d3;
}
.delete{
  color: #dc3545;
}
.org_admin_users_container{
  width: 80%;
  margin-top: 25px;
}
.add_new_org_user{
  margin-top: 10px;
  width: 20%;
  margin-left: 5px;
  margin-right: 5px;
}
.history_button{
  background-color: #30c03c;
}
.org_info_section h3{
  font-weight: bold;
  margin-top: 30px;
}
.org_info_section p{
  color: dimgray;
}
.org_menu_container{
  min-height: 300px;
  width: 90%;
  margin-top: 30px;
}
.org_menu_item_card{
  background-color: white;
  border-radius: 15px;
  min-height: 100px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.menu_controlers{
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: rgb(240, 240, 240);
}
.menu_controlers button{
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  background-color: #2275d3;
  color: white;
  width: 70%;
  border-radius: 50px;
  padding: 5px;
}
.org_card_menu_name{
  margin: 0px;
}
.org_card_menu_details{
  font-size: 13px;
}
.org_card_menu_price{
  color: #f0a400;
  font-weight: bold;
  font-size: 14px;
  margin: 0px;
}
.org_add_to_card_button{
  border: none;
  background-color: #30c03c;
  color: white;
  border-radius: 30px;
  height: 30px;
  width: 100px;
}
.org_remove_from_card_button{
  border: none;
  background-color: #dc3545;
  color: white;
  border-radius: 30px;
  height: 30px;
  width: 100px;
}
.amount_input{
  width: 100px;
  border: solid lightgray 1px;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 5px;
}
.bill_details p{
  margin: 0px;
}
.send_bill_button{
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  background-color: #2275d3;
  color: white;
  width: 70%;
  border-radius: 50px;
  padding: 5px;
}
.org_user_input_filed input{
  width: 70%;
  margin-bottom: 10px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border: solid lightgray 1px;
  border-radius: 50px;
  font-size: 14px;
}
.org_user_input_filed p{
  margin: 0px;
  width: 60%;
  text-align: left;
  color: dimgray;
}
.add_org_user_send{
  padding: 8px;
  margin-top: 15px;
}
.admin_survy_card{
  background-color: white;
  border-radius: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 90%;
  margin-top: 20px;
}
.admin_survy_card p{
  color: dimgray;
}
.admin_survy_card button{
  width: 80%;
}
.to_left{
  text-align: left;
}
.done_order_button{
  background-color: #2275d3;
  padding: 8px;
  height: auto;
  width: 70%;
}

.add_item_to_user_card{
  border: none;
  border-radius: 50px;
}
.done_deal_container{
  position: fixed;
  bottom: 45px;
  right: 0px;
  z-index: 100;
  width: 50%;
  background-color: white;
  padding: 20px;
  max-width: 250px;
  border-radius: 50px 0px 0px 50px;
}
.done_deal_container_button{
  border: none;
  background-color: #30c03c;
  color: white;
  padding: 8px;
  border-radius: 50px;
  width: 90%;
}
.one_item_counter{
  width: 150px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.one_item_counter button{
  border: none;
  background-color: lightgray;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding-top: 0px;
  outline: none;
  transition-duration: 0.1s;
}
.one_item_counter button:hover{
  background-color: gray;
}
.one_item_counter button:active{
  background-color: #353535;
  color: white;
}
.copy_button{
  background-color: #30c03c;
  border: none;
  border-radius: 50px;
  padding: 8px;
  width: 90%;
  margin-top: 20px;
  color: white;
}
.user_order_form{
  padding-bottom: 10px;
}
.user_order_form input{
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  border: solid lightgray 1px;
  border-radius: 50px;
  margin-bottom: 10px;
}
.edit_modal_frame_pill{
  padding-bottom: 50px;
  margin-top: 5%;
}
.user_menu_scroll_field{
  max-height: 240px;
  overflow-y: scroll;
}
.user_menu_scroll_field::-webkit-scrollbar{
  background-color: white;
  color: gray;
}
.user_menu_scroll_field::-webkit-scrollbar-thumb{
  background-color: lightgray;
  width: 5px;
  border-radius: 50px;
}
.org_user_home_button{
  margin: 10px;
  transition-duration: 0.2s;
}
.org_user_home_button:hover{
  scale: 1.05;
}
.org_user_home_button_primary{
  background-color: #30c03c;
}
.org_user_home_buttons_container{
  margin-top: 5%;
}
.org_user_home_button_seconday{
  background-color: #f0a400;
}
.org_user_home_buttons_container h3{
  font-weight: bold;
  margin: 0px;
}
.org_user_home_buttons_container h4{
  color: dimgray;
  margin-bottom: 20px;
}
.date_search_bar{
  background-color: white;
  width: 90%;
  max-width: 250px;
  padding: 5px;
  border-radius: 50px;
  text-align: right;
  height: 40px;
  margin-bottom: 20px;
}
.date_search_bar button{
  border: none;
  background-color: #30c03c;
  color: white;
  border-radius: 50px;
  outline: none;
  height: 100%;
  width: 20%;
  max-width: 60px;
  transition-duration: 0.1s;
}
.date_search_bar button:hover{
  scale: 1.1;
}
.date_search_bar button:active{
  scale: 0.9;
}
.date_search_bar input{
  border: none;
  outline: none;
  height: 100%;
  margin-right: 5%;
}
.org_user_history_card{
  background-color: white;
  text-align: right;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  margin-bottom: 20px;
}
.org_user_history_card p{
  margin: 0px;
}
.admin_history_button{
  width: 90%;
  background-color: #30c03c;
}
.admin_org_more_info{
  width: 90%;
  background-color: #2275d3;
}
.survy_selector_button{
  border: solid lightgray 1px;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  outline: none;
}
.survy_selector_button:focus{
  outline: none;
}
.survy_selector_button_active{
  background-color: #30c03c;
  color: white;
}
.ask_fro_survy_modal_botton{
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #30c03c;
}
.ask_fro_survy_modal_botton_secodary{
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f0a400;
}
.ask_fro_survy_modal_botton_danger{
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: -15px;
}
.org_user_home_button_danger{
  background-color: #dc3545;
}


.org_info_table td{
  max-width: 300px;
  font-size: 19px;
  /* border: solid lightgray 1px */
}
.control_one_org{
  width: 90%;
  max-width: 250px;
  border-radius: 50px;
  padding: 8px;
  border: none;
  margin-top: 20px;
  color: white;
  background-color: #30c03c;
}
.control_one_org_red{
  background-color: #dc3545;
}
.late_section{
  height: 95vh;
  background-color: #000000aa;
}
.late_section h3{
  padding-top: 35vh;
  color: white;
}
.late_section h4{
  color: white;
}









/* .user_logout_button_container{
  width: 90%;
  text-align: right;
}
.user_logout_button_container button{
  border: none;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  outline: none;
  background-color: #dc3545;
  color: white;
} */





/* ################################################################# */
/* ################################################################# */
/* ################################################################# */
/* ################################################################# */
/* ################################################################# */
/* ################################################################# */


@media (max-width: 450px) and (min-width: 100px){
  .hedaer_logo{
    height: 50px;
    margin-top: 15px;
  }
  .input_filed{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
  }
  .org_login_form_container{
    padding: 15px;
    padding-bottom: 30px;
    margin-top: 25%;
  }
  .org_login_form_container label{
    font-size: 13px;
  }
  .org_form_button{
    padding: 4px;
    width: 100%;
  }
  .org_admin_users_container{
    margin: 5px;
  }
  .add_new_org_user{
    width: 150px;
    min-width: 150px;
    margin-bottom: 3px;
  }
  .org_admin_user_card h4{
    font-size: 15px;
  }
  .org_admin_user_card p{
    font-size: 13px;
    margin-bottom: 5px;
  }
  .org_admin_user_card button{
    font-size: 14px;
    margin-top: 8px;
  }
  .org_admin_user_card_link{
    font-size: 13px;
  }
  .org_info_section h3{
    font-size: 15px;
  }
  .org_info_section p{
    font-size: 13px;
    width: 90%;
  }
  .admin_survy_card h3{
    font-size: 17px;
  }
  .admin_survy_card p{
    width: 80%;
    font-size: 14px;
  }
  .admin_survy_card button{
    width: 80%;
    font-size: 14px;
  }
  .done_deal_container{
    padding: 8px;
  }
  .done_deal_container_button{
    font-size: 13px;
  }
  .user_order_form input{
    width: 95%;
  }
}
